var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c(
      "div",
      {
        staticClass: "footer-wrapper",
        staticStyle: { padding: "45px 0 10px" }
      },
      [
        _vm._m(0),
        _c("p", [
          _vm._v(
            _vm._s(_vm.config.site_name) +
              "是一家中立的导购网站，网站中的商品信息均来自于互联网。如商品信息不同，可能是商品信息未及时更新，所有商品信息请以天猫/淘宝店铺内为准。"
          )
        ]),
        _c("div", { staticClass: "text" }, [
          _c("span", [
            _vm._v("版权所有 本站内容未经书面许可,禁止一切形式的转载")
          ]),
          _c("span", { staticClass: "ml" }, [
            _vm._v("© CopyRight " + _vm._s(new Date().getFullYear()))
          ]),
          _c(
            "a",
            {
              staticStyle: { padding: "0 8px" },
              attrs: {
                href: _vm.config.yuming
                  ? "//" + _vm.config.yuming
                  : "javascript:void(0)",
                target: "_blank"
              }
            },
            [_vm._v(_vm._s(_vm.config.site_name) + _vm._s(_vm.config.yuming))]
          ),
          _c(
            "a",
            {
              staticClass: "miitbeian ml",
              staticStyle: { padding: "0 8px" },
              attrs: {
                href: "https://beian.miit.gov.cn/",
                target: "_blank",
                rel: "nofollow"
              }
            },
            [_vm._v(_vm._s(_vm.config.beianhao))]
          ),
          _vm.config.leftTxt
            ? _c("span", {
                staticStyle: { padding: "0 8px" },
                domProps: { innerHTML: _vm._s(_vm.config.leftTxt) }
              })
            : _vm._e(),
          _c(
            "a",
            {
              staticStyle: {
                "margin-left": "20px",
                "text-decoration": "underline"
              },
              attrs: { href: "?r=index/feedbackpc", target: "_blank" }
            },
            [_vm._v("我要反馈")]
          ),
          _vm._m(1),
          _vm.config.custom_script && _vm.config.custom_script_title
            ? _c(
                "p",
                {
                  staticClass: "ml",
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center"
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.config.custom_script,
                      alt: "load...",
                      width: "26"
                    }
                  }),
                  _c(
                    "a",
                    {
                      staticStyle: { "margin-left": "3px" },
                      attrs: { href: _vm.config.custom_script_url }
                    },
                    [_vm._v(_vm._s(_vm.config.custom_script_title))]
                  )
                ]
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("网站导航")]),
      _c("li", [
        _c("a", { attrs: { href: "?r=/index/index", target: "_blank" } }, [
          _vm._v("首页")
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "?r=/p", target: "_blank" } }, [
          _vm._v("疯抢榜")
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "?r=/ddq", target: "_blank" } }, [
          _vm._v("咚咚抢")
        ])
      ]),
      _c("li", [
        _c("a", { attrs: { href: "?r=/nine", target: "_blank" } }, [
          _vm._v("9.9包邮")
        ])
      ]),
      _c("li", { staticClass: "no" }, [
        _c("a", { attrs: { href: "?r=/realtime/zsz", target: "_blank" } }, [
          _vm._v("折上折")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "miitbeian ml",
        attrs: { href: "javascript:void(0)", rel: "nofollow" }
      },
      [
        _c("p", {
          staticClass: "ml",
          staticStyle: {
            display: "flex",
            "align-items": "center",
            "justify-content": "center"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }